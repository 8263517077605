import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  continueToPaymentAfterLogin: false,
};

const EventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    setContinueToPaymentAfterLogin: (state, action) => {
      state.continueToPaymentAfterLogin = action.payload.continueToPaymentAfterLogin;
    },
  },
});

export const { setContinueToPaymentAfterLogin } = EventsSlice.actions;
export default EventsSlice.reducer;
