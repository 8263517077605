import React from "react";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import { Block } from "../../components/Component";

const EmailVerification = () => {
  return (
    <>
      <Head title="Success" />
      <Block className="nk-block-middle nk-auth-body"></Block>
      <AuthFooter />
    </>
  );
};
export default EmailVerification;
