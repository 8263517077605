import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  language: "en",
  rtl: false,
};

const LanguageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    setLanguage: (state, action) => {
      state.language = action.payload.language;
      state.rtl = action.payload.rtl;
    },
  },
});

export const { setLanguage } = LanguageSlice.actions;
export default LanguageSlice.reducer;
