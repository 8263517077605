import { toast } from "react-toastify";
import { logout } from "../../redux/slices/UserSlice";
import { store } from "../../redux/store";

export const setupInterceptors = (client) => {
  client.interceptors.request.use(
    (config) => {
      const state = store.getState();
      const accessToken = state.user.token;

      if (config.delayed) {
        return new Promise((resolve) => setTimeout(() => resolve(config), 500));
      }

      // Check if the request URL matches any in the publicUrls
      if (accessToken) {
        config.headers = config.headers || {};
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  client.interceptors.response.use(
    (response) => response,
    async (error) => {
      const { data } = error?.response;

      // Check if Error is for Expired Auth Token error,
      // then logout the user
      if (data?.result === "session_expired") {
        const storeModule = import("../../redux/store");
        const { store } = await storeModule;

        delete client.defaults.headers.common["Authorization"];

        // Logout the user
        store.dispatch(logout());
        window.location.href = "/login";

        toast.error("Session Expired. Please login again.");
      }

      return Promise.reject(error);
    }
  );
};
