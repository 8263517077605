const introCard = [
  {
    icon: "profile",
    title: "Personal Info",
    description: "See your profile data and manage your Account to choose what is saved in our system.",
    linkTo: "/profile",
    linkText: "Manage Your Account",
  },
  {
    icon: "reports",
    title: "My Subscriptions",
    description: "Check your subscriptions and manage your subscriptions that you have.",
    linkTo: "/subscriptions",
    linkText: "Manage Subscription",
  },
  {
    icon: "invoice",
    title: "Billing History",
    description: "Check out all your payment history. You can also download or print your invoice.",
    linkTo: "/payments",
    linkText: "Payment History",
  },
];

export default introCard;
