import { get, post } from "../../network/Config/apiService";

const GetSubscriptionsOfUser = async () => {
  try {
    const res = await get("/subscription/all");

    return {
      result: "success",
      message: "Subscriptions fetched successfully",
      subscriptions: res.data.subscriptions,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not fetch Subscriptions",
      subscriptions: [],
    };
  }
};

const CancelSubscription = async (subscriptionId) => {
  if (!subscriptionId) {
    throw new Error("Subscription ID is required");
  }

  try {
    await post(`/subscription/mark-cancelled/${subscriptionId}`);

    return {
      result: "success",
      message: "Subscription canceled successfully",
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not cancel subscription",
    };
  }
};

// Get user's current month Subscriptions
const GetSubscriptionsCurrent = async () => {
  try {
    const res = await get("/subscription/get-active");

    const subscriptions = res?.data?.subscriptions ?? [];

    return {
      result: "success",
      message: "Subscriptions fetched successfully",
      subscriptions: subscriptions,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not fetch subscriptions",
      subscriptions: [],
    };
  }
};

export { GetSubscriptionsOfUser, CancelSubscription, GetSubscriptionsCurrent };
