import React, { useEffect, useState } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
} from "../../../components/Component";
import { Link } from "react-router-dom";
import { Card, Spinner } from "reactstrap";
import { svgData } from "../../components/crafted-icons/NioIconData";
import { GetSubscriptionsOfUser } from "../../../network/apis/subscriptions";
import { toast } from "react-toastify";
import SubscriptionCard from "../../../components/subscription/SubscriptionCard";
const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const Subscriptions = () => {
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [fetchingCurrentPlan, setFetchingCurrentPlan] = useState(false);

  const getCurrentPlan = async () => {
    try {
      setFetchingCurrentPlan(true);
      const res = await GetSubscriptionsOfUser();

      if (res.result === "success" && res.subscriptions) {
        setActiveSubscriptions(res.subscriptions);
      } else {
        setActiveSubscriptions([]);
      }
      setFetchingCurrentPlan(false);
    } catch (error) {
      toast.error("Failed to get current plan. Please try again later.");
      setActiveSubscriptions([]);
      setFetchingCurrentPlan(false);
    }
  };

  useEffect(() => {
    getCurrentPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Head title="Subscriptions"></Head>
      {fetchingCurrentPlan ? (
        <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
          <Spinner size="lg" color="primary" />
        </section>
      ) : (
        <>
          <Content>
            <BlockHead size="lg">
              <div className="nk-block-head-sub">
                <span>Manage Subscription</span>
              </div>
              <BlockBetween size="md" className="g-4">
                <BlockHeadContent>
                  <BlockTitle tag="h2" className="fw-normal">
                    My Subscriptions
                  </BlockTitle>
                  <BlockDes>
                    <p>
                      Here is list of package/product that you have subscribed.{" "}
                      <span className="text-primary">
                        <Icon name="info" />
                      </span>
                    </p>
                  </BlockDes>
                </BlockHeadContent>
                <BlockHeadContent>
                  <ul className="nk-block-tools gx-3">
                    <li className="order-md-last">
                      <Link
                        to={`${process.env.PUBLIC_URL}/pricing`}
                        className="btn btn-white btn-dim btn-outline-primary"
                      >
                        <span>View Pricing</span>
                      </Link>
                    </li>
                  </ul>
                </BlockHeadContent>
              </BlockBetween>
            </BlockHead>
            <Block>
              {activeSubscriptions?.map((item, index) => (
                <SubscriptionCard key={index} item={item} refreshUI={getCurrentPlan} />
              ))}
            </Block>
            <Block>
              <Card className="card-bordered">
                <div className="card-inner">
                  <div className="nk-help">
                    <div className="nk-help-img">{helpSupportIcon.svg}</div>
                    <div className="nk-help-text">
                      <h5>We're here to help you!</h5>
                      <p className="text-soft">
                        Ask a question or file a support ticket or report an issues. Our team support team will get back
                        to you by email.
                      </p>
                    </div>
                    <div className="nk-help-action">
                      <Link to={`${process.env.PUBLIC_URL}/contact`} className="btn btn-lg btn-outline-primary">
                        Get Support Now
                      </Link>
                    </div>
                  </div>
                </div>
              </Card>
            </Block>
          </Content>
        </>
      )}
    </React.Fragment>
  );
};

export default Subscriptions;
