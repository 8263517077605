import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
} from "../../../components/Component";
import { Outlet } from "react-router-dom";

const Profile = () => {
  return (
    <React.Fragment>
      <Head title="Profile"></Head>
      <Content>
        <BlockHead size="md">
          <div className="nk-block-head-sub">
            <span>Account Setting</span>
          </div>
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                My Profile
              </BlockTitle>
              <BlockDes>
                <p>
                  You have full control to manage your own account setting.{" "}
                  <span className="text-primary">
                    <Icon name="info" />
                  </span>
                </p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Outlet />
      </Content>
    </React.Fragment>
  );
};

export default Profile;
