import { get, post } from "../../network/Config/apiService";

const GetTransactionsOfUser = async (userId) => {
  try {
    const res = await get("/transaction/all");

    return {
      result: "success",
      message: "Transactions fetched successfully",
      transactions: res.data.transactions,
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not fetch transactions",
      transactions: [],
    };
  }
};

const DeleteTransaction = async (transactionId) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  try {
    await post(`/transaction/mark-deleted/${transactionId}`);

    return {
      result: "success",
      message: "Transaction deleted successfully",
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not delete transaction",
    };
  }
};

const CancelTransaction = async (transactionId) => {
  if (!transactionId) {
    throw new Error("Transaction ID is required");
  }

  try {
    await post(`/transaction/mark-cancelled/${transactionId}`);

    return {
      result: "success",
      message: "Transaction canceled successfully",
    };
  } catch (error) {
    return {
      result: "error",
      message: error?.response?.data?.message || error.message || "Could not cancel transaction",
    };
  }
};

const GetAllPricePlans = async () => {
  try {
    const res = await get("/pricing");

    const data = res.data.pricePlans;

    return {
      status: "success",
      message: "Price plans fetched successfully",
      data: data,
    };
  } catch (error) {
    return {
      status: "error",
      message: error.message || "Could not fetch price plans",
      data: null,
    };
  }
};

export {
  GetTransactionsOfUser,
  DeleteTransaction,
  CancelTransaction,
  GetAllPricePlans,
};
