import React, { useEffect, useState } from "react";
import { BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon } from "../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import { LoginWithEmailAndPassword } from "../../../network/apis/auth";
import { useDispatch } from "react-redux";
import { setLogin } from "../../../redux/slices/UserSlice";

function LoginForm({ hideModal = () => {}, showSignup = () => {}, showReset = () => {}, afterSuccess = () => {} }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [passState, setPassState] = useState(false);
  const [errorVal, setError] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLoginSubmit = async () => {
    if (email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/) && password) {
      setLoading(true);

      try {
        const res = await LoginWithEmailAndPassword(email, password);
        setLoading(false);
        if (res.result === "error") {
          setError(res.message);
        } else {
          dispatch(
            setLogin({
              user: res.data.userData,
              token: res.data.token,
            })
          );
          hideModal();
          afterSuccess();
        }
      } catch (error) {
        setLoading(false);
        setError(error?.message ?? "Cannot login with credentials");
      }
    } else {
      if (!email) {
        setError("Email is required");
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/)) {
        setError("Email is invalid");
      } else if (!password) {
        setError("Passcode is required");
      }
    }
  };

  useEffect(() => {
    if (errorVal) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [errorVal]);

  return (
    <section>
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Sign-In</BlockTitle>
          <BlockDes>
            <p>Access Naxixa using your email and passcode.</p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}
          </Alert>
        </div>
      )}
      <form
        className="is-alter"
        onSubmit={(e) => {
          e.preventDefault();
          handleLoginSubmit();
        }}
      >
        <div className="form-group">
          <div className="form-label-group">
            <label className="form-label" htmlFor="default-01">
              Email
            </label>
          </div>
          <div className="form-control-wrap">
            <input
              type="email"
              id="default-01"
              defaultValue=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              className="form-control-lg form-control"
              required
            />
          </div>
        </div>
        <div className="form-group">
          <div className="form-label-group">
            <label className="form-label" htmlFor="password">
              Password
            </label>
            <button
              type="button"
              className="link link-primary link-sm"
              onClick={() => {
                showReset();
              }}
            >
              Forgot Password?
            </button>
          </div>
          <div className="form-control-wrap">
            <button
              type="button"
              href="#password"
              onClick={(ev) => {
                ev.preventDefault();
                setPassState(!passState);
              }}
              className={`btn form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
            >
              <Icon name="eye" className="passcode-icon icon-show"></Icon>

              <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
            </button>
            <input
              type={passState ? "text" : "password"}
              id="password"
              defaultValue=""
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your passcode"
              className={`form-control-lg form-control ${passState ? "is-hidden" : "is-shown"}`}
            />
          </div>
        </div>
        <div className="form-group">
          <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
            {loading ? <Spinner size="sm" color="light" /> : "Sign in"}
          </Button>
        </div>
      </form>

      <div className="form-note-s2 text-center pt-4">
        New on our platform?{" "}
        <button
          type="button"
          className="link link-primary link-sm"
          onClick={() => {
            showSignup();
          }}
        >
          Create an account
        </button>
      </div>
    </section>
  );
}

export default LoginForm;
