import React, { Fragment, useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import {
  Button,
  Block,
  Col,
  Icon,
  Row,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  BlockHead,
  RSelect
} from "../../../components/Component";
import { Card, Spinner } from "reactstrap";
import { Modal, ModalBody } from "reactstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Content from "../../../layout/content/Content";
import { AddUpdateTokensOfUser, GetTokensOfUser } from "../../../network/apis/tokens";
import { GetSubscriptionsCurrent } from "../../../network/apis/subscriptions";

const TokensManagement = () => {
  const { user } = useSelector((state) => state.user);
  const [modal, setModal] = useState(false);
  const [fetchingTokens, setFetchingTokens] = useState(false);
  const [updatingTokens, setUpdatingTokens] = useState(false);
  const [haveBotAccess, setHaveBotAccess] = useState(false);

  const [userTokens, setUserTokens] = useState({
    apiKey: "",
    apiSecret: "",
    telegramToken: "",
    telegramChatId: "",
  });

  const [enteredApiKey, setEnteredApiKey] = useState("");
  const [enteredApiSecret, setEnteredApiSecret] = useState("");
  const [enteredTelegramToken, setEnteredTelegramToken] = useState("");
  const [enteredTelegramChatId, setEnteredTelegramChatId] = useState("");

  const [activeTab, setActiveTab] = useState("Spot");
  const [marginMode, setMarginMode] = useState("isolated");
  const [leverage, setLeverage] = useState(10);
  const [selectedExchange, setSelectedExchange] = useState("CoinEx");
  const [jsonCopySuccess, setJsonCopySuccess] = useState("");
  const [urlCopySuccess, setUrlCopySuccess] = useState("");

  const userId = user.uid;
  const passphrase = ":~:l@gnI|xQ#7925*7d1N}1E>";

  const jsonTemplates = {
    BingX: {
      spotJson: `{
    "passphrase": "s-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`,
      futuresJson: `{
    "passphrase": "f-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "message" : "futures_buy_sell",
        "leverage" : ${leverage},
        "margin_mode" : "${marginMode}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`
    },
    CoinEx: {
      spotJson: `{
    "passphrase": "s-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`,
      futuresJson: `{
    "passphrase": "f-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "message" : "futures_buy_sell",
        "leverage" : ${leverage},
        "margin_mode" : "${marginMode}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`
    },
    Bybit: {
      spotJson: `{
    "passphrase": "s-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`,
      futuresJson: `{
    "passphrase": "f-${passphrase}",
    "time": "{{timenow}}",
    "exchange": "{{exchange}}",
    "ticker": "{{ticker}}",
    "strategy": {
        "position_size": {{strategy.position_size}},
        "order_action": "{{strategy.order.action}}",
        "message" : "futures_buy_sell",
        "leverage" : ${leverage},
        "margin_mode" : "${marginMode}",
        "TimeFrame" : "{{interval}}",
        "order_contracts": {{strategy.order.contracts}},
        "order_price": {{strategy.order.price}},
        "order_id": "{{strategy.order.id}}",
        "alert_message": "{{strategy.order.alert_message}}"
    },
    "user_id": "${userId}"
}`
    }
  };

  const activeJson =
    activeTab === "Spot"
      ? jsonTemplates[selectedExchange].spotJson
      : jsonTemplates[selectedExchange].futuresJson;

  const handleCopy = (text, type) => {
    try {
      navigator.clipboard.writeText(text);
      if (type === "JSON") {
        setJsonCopySuccess("Alert Message copied to clipboard!");
        setTimeout(() => setJsonCopySuccess(""), 2000);
      } else if (type === "URL") {
        setUrlCopySuccess("Webhook URL copied to clipboard!");
        setTimeout(() => setUrlCopySuccess(""), 2000);
      }
    } catch (err) {
      if (type === "JSON") setJsonCopySuccess("Failed to copy Alert Message");
      if (type === "URL") setUrlCopySuccess("Failed to copy Webhook URL");
    }
  };

  const exchangeOptions = [
    { value: "Bybit", label: "Bybit" },
    { value: "CoinEx", label: "CoinEx" },
    { value: "BingX", label: "BingX" }
  ];

  const exchangeUrls = {
    BingX: "https://bot.navixa.io/bingx",
    CoinEx: "https://bot.navixa.io/coinex",
    Bybit: "https://bot.navixa.io/bybit"
  };

  const submitForm = async () => {
    if (
      enteredApiKey.trim() &&
      enteredApiSecret.trim() &&
      enteredTelegramToken.trim() &&
      enteredTelegramChatId.trim()
    ) {
      setUpdatingTokens(true);
      try {
        const res = await AddUpdateTokensOfUser(user?.uid, {
          apiKey: enteredApiKey,
          apiSecret: enteredApiSecret,
          telegramToken: enteredTelegramToken,
          telegramChatId: enteredTelegramChatId,
        });

        if (res.result === "success") {
          toast.success("Tokens updated successfully");
          setModal(false);
          getUserTokens(user.uid);
        } else {
          toast.error(res?.message ?? "Something went wrong. Try Again!");
        }

        setUpdatingTokens(false);
      } catch (error) {
        toast.error(error?.message ?? "Something went wrong. Try Again!");
        setUpdatingTokens(false);
      }
    } else {
      toast.error("Please fill all fields");
    }
  };

  const getUserTokens = async (userId) => {
    setFetchingTokens(true);
    try {
      const res = await GetTokensOfUser(userId);
      if (res.result === "success") {
        const tokens = res?.data?.tokens ?? {};
        setUserTokens(tokens);
        setEnteredApiKey(tokens?.apiKey ?? "");
        setEnteredApiSecret(tokens?.apiSecret ?? "");
        setEnteredTelegramToken(tokens?.telegramToken ?? "");
        setEnteredTelegramChatId(tokens?.telegramChatId ?? "");
      } else {
        toast.error(res?.message ?? "Error fetching user tokens");
      }
      setFetchingTokens(false);
    } catch (error) {
      setFetchingTokens(false);
      toast.error(error?.message ?? "Error fetching user tokens");
    }
  };

  const resetValues = () => {
    setEnteredApiKey(userTokens?.apiKey ?? "");
    setEnteredApiSecret(userTokens?.apiSecret ?? "");
    setEnteredTelegramToken(userTokens?.telegramToken ?? "");
    setEnteredTelegramChatId(userTokens?.telegramChatId ?? "");
  };

  const checkIfUserIsAllowed = async (userId) => {
    try {
      setFetchingTokens(true);
      const res = await GetSubscriptionsCurrent();

      if (res.result === "success" && res.subscriptions) {
        // Check if atleast one transaction has planData.isBotIncluded = true && planData.isBotOptionAvailable = true
        const hasBotAccess = res.subscriptions.some((transaction) => {
          return transaction?.planData?.isBotIncluded && transaction?.planData?.isBotOptionAvailable;
        });
        setHaveBotAccess(hasBotAccess);
        if (hasBotAccess) {
          getUserTokens(userId);
        }
      } else {
        setHaveBotAccess(false);
      }
      setFetchingTokens(false);
    } catch (error) {
      toast.error("Failed to get current plan. Please try again later.");
      setHaveBotAccess(false);
      setFetchingTokens(false);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      checkIfUserIsAllowed(user.uid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <React.Fragment>
      <Head title="Tokens and Keys"></Head>
      {fetchingTokens ? (
        <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
          <Spinner size="lg" color="primary" />
        </section>
      ) : (
        <Content>
          {haveBotAccess ? (
            <>
              <BlockHead size="md">
                <div className="nk-block-head-sub">
                  <span>Tokens Management</span>
                </div>
                <BlockBetween>
                  <BlockHeadContent>
                    <BlockTitle tag="h2" className="fw-normal">
                      Tokens and Keys
                    </BlockTitle>
                    <BlockDes>
                      <p>You can manage your tokens and keys here for bot access. </p>
                    </BlockDes>
                  </BlockHeadContent>
                </BlockBetween>
              </BlockHead>

              <Block>
                <Card className="card-bordered">
                  {fetchingTokens ? (
                    <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
                      <Spinner size="lg" color="primary" />
                    </section>
                  ) : (
                    <section className="nk-data data-list">
                      <section className="data-item">
                        <div className="data-col">
                          <span className="data-label">Api Key</span>
                          <span
                            className="data-value"
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                            }}
                          >
                            {userTokens?.apiKey ?? "N/A"}
                          </span>
                        </div>
                        <div className="data-col data-col-end">
                          <span className="data-more" onClick={() => setModal(true)}>
                            <Icon name="forward-ios"></Icon>
                          </span>
                        </div>
                      </section>
                      <section className="data-item">
                        <div className="data-col">
                          <span className="data-label">Api Secret</span>
                          <span
                            className="data-value"
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                            }}
                          >
                            {userTokens?.apiSecret ?? "N/A"}
                          </span>
                        </div>
                        <div className="data-col data-col-end">
                          <span className="data-more" onClick={() => setModal(true)}>
                            <Icon name="forward-ios"></Icon>
                          </span>
                        </div>
                      </section>

                      <section className="data-item">
                        <div className="data-col">
                          <span className="data-label">Telegram Token</span>
                          <span
                            className="data-value"
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                            }}
                          >
                            {userTokens?.telegramToken ?? "N/A"}
                          </span>
                        </div>
                        <div className="data-col data-col-end">
                          <span className="data-more" onClick={() => setModal(true)}>
                            <Icon name="forward-ios"></Icon>
                          </span>
                        </div>
                      </section>

                      <section className="data-item">
                        <div className="data-col">
                          <span className="data-label">Telegram Chat Id</span>
                          <span
                            className="data-value"
                            style={{
                              wordWrap: "break-word",
                              wordBreak: "break-all",
                            }}
                          >
                            {userTokens?.telegramChatId ?? "N/A"}
                          </span>
                        </div>
                        <div className="data-col data-col-end">
                          <span className="data-more" onClick={() => setModal(true)}>
                            <Icon name="forward-ios"></Icon>
                          </span>
                        </div>
                      </section>
                    </section>
                  )}
                </Card>
              </Block>
              <Modal
                isOpen={modal}
                className="modal-dialog-centered"
                size="lg"
                toggle={() => {
                  setModal(false);
                  resetValues();
                }}
              >
                <ModalBody>
                  <a
                    href="#dropdownitem"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setModal(false);
                      resetValues();
                    }}
                    className="close"
                  >
                    <Icon name="cross-sm"></Icon>
                  </a>
                  <div className="p-2">
                    <h5 className="title">Update Tokens and Keys</h5>

                    <div className="tab-content mt-4">
                      <div className={`tab-pane active`} id="tokens">
                        <Row className="gy-4">
                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="api-key">
                                Api Key
                              </label>
                              <input
                                type="text"
                                id="api-key"
                                className="form-control"
                                name="api-key"
                                onChange={(e) => {
                                  setEnteredApiKey(e.target.value);
                                }}
                                value={enteredApiKey}
                                placeholder="Enter Api Key"
                              />
                            </div>
                          </Col>

                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="api-secret">
                                Api Secret
                              </label>
                              <input
                                type="text"
                                id="api-secret"
                                className="form-control"
                                name="api-secret"
                                onChange={(e) => {
                                  setEnteredApiSecret(e.target.value);
                                }}
                                value={enteredApiSecret}
                                placeholder="Enter Api Secret"
                              />
                            </div>
                          </Col>

                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="telegram-token">
                                Telegram Token
                              </label>
                              <input
                                type="text"
                                id="telegram-token"
                                className="form-control"
                                name="telegram-token"
                                onChange={(e) => {
                                  setEnteredTelegramToken(e.target.value);
                                }}
                                value={enteredTelegramToken}
                                placeholder="Enter Telegram Token"
                              />
                            </div>
                          </Col>
                          <Col md="12">
                            <div className="form-group">
                              <label className="form-label" htmlFor="telegram-chat-id">
                                Telegram Chat Id
                              </label>
                              <input
                                type="text"
                                id="telegram-chat-id"
                                className="form-control"
                                name="telegram-chat-id"
                                onChange={(e) => {
                                  setEnteredTelegramChatId(e.target.value);
                                }}
                                value={enteredTelegramChatId}
                                placeholder="Enter Telegram Chat Id"
                              />
                            </div>
                          </Col>

                          <Col size="12">
                            <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                              <li>
                                <Button
                                  color="primary"
                                  size="lg"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    submitForm();
                                  }}
                                  disabled={updatingTokens || fetchingTokens}
                                >
                                  {updatingTokens ? <Spinner size="sm" color="light" /> : "Update Tokens"}
                                </Button>
                              </li>
                              <li>
                                <a
                                  href="#dropdownitem"
                                  onClick={(ev) => {
                                    ev.preventDefault();
                                    setModal(false);
                                    resetValues();
                                  }}
                                  className="link link-light"
                                  disabled={updatingTokens || fetchingTokens}
                                >
                                  Cancel
                                </a>
                              </li>
                            </ul>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </>
          ) : (
            <>
              <section className="d-flex flex-row justify-content-center align-items-center py-5 ">
                <h3>No Bot Access</h3>
              </section>

              <section className="d-flex flex-row justify-content-center align-items-center">
                <p>You do not have access to bot. Please subscribe to a plan that includes bot access.</p>
              </section>
            </>
          )}

<section style={{ direction: "ltr", padding: "28px 0" }}>
            <div className="json-box-container">
              <RSelect
                value={exchangeOptions.find((option) => option.value === selectedExchange)}
                onChange={(selectedOption) => setSelectedExchange(selectedOption.value)}
                options={exchangeOptions}
                className="custom-class"
                placeholder="Select an exchange"
              />

              <div className="tabs">
                <button
                  className={`tab-button ${activeTab === "Spot" ? "active" : ""}`}
                  onClick={() => setActiveTab("Spot")}
                  style={{ padding: "6px 20px" }}
                >
                  Spot
                </button>
                <button
                  className={`tab-button ${activeTab === "Futures" ? "active" : ""}`}
                  onClick={() => setActiveTab("Futures")}
                  style={{ padding: "6px 20px" }}
                >
                  Futures
                </button>
              </div>

              {activeTab === "Futures" && (
                <Fragment>
                  <div style={{ marginTop: "10px" }}>
                    <span>Margin Mode: </span>
                    <button
                      className={`margin-button ${marginMode === "isolated" ? "active" : ""}`}
                      onClick={() => setMarginMode("isolated")}
                      style={{  marginLeft: "10px", padding: "6px 20px" }}
                    >
                      Isolated
                    </button>
                    <button
                      className={`margin-button ${marginMode === "cross" ? "active" : ""}`}
                      onClick={() => setMarginMode("cross")}
                      style={{ padding: "6px 20px" }}
                    >
                      Cross
                    </button>
                  </div>
                  <div style={{ marginTop: "10px" }}>
                    <span>Leverage: </span>
                    <input
                      type="number"
                      value={leverage}
                      onChange={(e) => setLeverage(parseInt(e.target.value, 10))}
                      placeholder=""
                      style={{  marginLeft: "36px", padding: "5px", width: "60px", border: "1px solid #ddd", borderRadius: "5px" }}
                    />
                  </div>
                </Fragment>
              )}

              <pre className="json-box">{activeJson}</pre>
              <button
                className="copy-button"
                onClick={() => handleCopy(activeJson, "JSON")}
                style={{ padding: "7px 20px" }}
              >
                Copy Alert Message
              </button>
              {jsonCopySuccess && <div className="copy-success" style={{ marginTop: "10px" }}>{jsonCopySuccess}</div>}
            </div>

            <div className="json-box-container">
              <input
                type="text"
                value={exchangeUrls[selectedExchange]}
                readOnly
                style={{ width: "100%", padding: "5px", backgroundColor: "#f9f9f9", border: "1px solid #ccc", borderRadius: "3px" }}
              />
              <button
                className="copy-button"
                onClick={() => handleCopy(exchangeUrls[selectedExchange], "URL")}
                style={{ marginTop: "10px", padding: "7px 20px" }}
              >
                Copy Webhook URL
              </button>
              {urlCopySuccess && <div className="copy-success" style={{ marginTop: "10px" }}>{urlCopySuccess}</div>}
            </div>
          </section>
        </Content>
      )}
    </React.Fragment>
  );
};

export default TokensManagement;
