export const menu = [
  { heading: "Menu" },
  {
    icon: "dashboard",
    text: "Dashboard",
    link: "/index",
  },
  {
    icon: "file-text",
    text: "My Subscriptions",
    link: "/subscriptions",
  },
  {
    icon: "file-text",
    text: "Pricing",
    link: "/pricing",
  },
  {
    icon: "report-profit",
    text: "Payment History",
    link: "/payments",
  },
  {
    icon: "lock-alt",
    text: "Tokens & Keys",
    link: "/tokens",
  },
  {
    icon: "account-setting",
    text: "Account Setting",
    link: "/profile",
  },
];
export const secoundmenu = [
  {
    heading: "Help Center",
  },
  {
    text: "FAQs",
    link: "/faqs",
  },
  {
    text: "Contact",
    link: "/contact",
  },
  {
    text: "Support",
    link: "/support",
  },
];
export default menu;
