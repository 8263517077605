import { configureStore, combineReducers } from "@reduxjs/toolkit";
import UserReducer from "./slices/UserSlice";
import LanguageReducer from "./slices/LanguageSlice";
import CartReducer from "./slices/CartSlice";
import EventsReducer from "./slices/EventsSlice";
import createWebStorage from "redux-persist/lib/storage/createWebStorage";
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

const createNoopStorage = () => {
  return {
    getItem(_key) {
      return Promise.resolve(null);
    },
    setItem(_key, value) {
      return Promise.resolve(value);
    },
    removeItem(_key) {
      return Promise.resolve();
    },
  };
};

const storage = typeof window !== "undefined" ? createWebStorage("local") : createNoopStorage();

const UserPersistConfig = {
  key: "user",
  version: 1,
  storage,
  whitelist: ["otp", "user", "token"],
};

const LanguagePersistConfig = {
  key: "language",
  version: 1,
  storage,
  whitelist: [],
};

const CartPersistConfig = {
  key: "cart",
  version: 1,
  storage,
  whitelist: ["items"],
};

const EventsPersistConfig = {
  key: "events",
  version: 1,
  storage,
  whitelist: [],
};

const persistedReducer = combineReducers({
  user: persistReducer(UserPersistConfig, UserReducer),
  language: persistReducer(LanguagePersistConfig, LanguageReducer),
  cart: persistReducer(CartPersistConfig, CartReducer),
  events: persistReducer(EventsPersistConfig, EventsReducer),
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
