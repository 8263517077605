import React from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Block,
  BlockBetween,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Col,
  Icon,
  Row,
} from "../../../components/Component";
import { Card } from "reactstrap";
import { Link } from "react-router-dom";
import { svgData } from "../../components/crafted-icons/NioIconData";
import introCard from "./data/home";

const helpSupportIcon = svgData.filter((icon) => icon.slug === "help-support")[0];

const Dashboard = () => {
  return (
    <React.Fragment>
      <Head title="Dashboard"></Head>
      <Content>
        <BlockHead size="lg">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h2" className="fw-normal">
                Dashboard
              </BlockTitle>
              <BlockDes>
                <p>Welcome to our dashboard. Manage your account and your subscriptions.</p>
              </BlockDes>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        <Block>
          <Row className="g-gs">
            {introCard.map((item, index) => {
              const icon = svgData.filter((icon) => icon.slug === item.icon)[0];
              return (
                <Col md="6" key={index} className="mx-auto">
                  <Card className="card-bordered card-full">
                    <div className="nk-wg1">
                      <div className="nk-wg1-block">
                        <div className="nk-wg1-img">{icon.svg}</div>
                        <div className="nk-wg1-text">
                          <h5 className="title">{item.title}</h5>
                          <p>{item.description}</p>
                        </div>
                      </div>
                      <div className="nk-wg1-action">
                        <Link to={`${process.env.PUBLIC_URL}${item.linkTo}`} className="link">
                          <span>{item.linkText}</span> <Icon name="chevron-right"></Icon>
                        </Link>
                      </div>
                    </div>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Block>

        <Block>
          <Card className="card-bordered">
            <div className="card-inner">
              <div className="nk-help">
                <div className="nk-help-img">{helpSupportIcon.svg}</div>
                <div className="nk-help-text">
                  <h5>We’re here to help you!</h5>
                  <p className="text-soft">
                    Ask a question or file a support ticket or report an issues. Our team support team will get back to
                    you by email.
                  </p>
                </div>
                <div className="nk-help-action">
                  <Link
                    to={`${process.env.PUBLIC_URL}/contact`}
                    className="btn btn-lg btn-outline-primary"
                  >
                    Get Support Now
                  </Link>
                </div>
              </div>
            </div>
          </Card>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default Dashboard;
