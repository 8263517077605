import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Logo from "../logo/Logo";
import User from "./dropdown/user/UserSubscription";
import Toggle from "../sidebar/Toggle";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import { useSelector } from "react-redux";
import { Alert } from "reactstrap";
import { Icon } from "../../components/Component";
import { Link } from "react-router-dom";

const Header = ({ fixed, className, ...props }) => {
  const { rtl } = useSelector((state) => state.language);
  const { user } = useSelector((state) => state.user);
  const { items } = useSelector((state) => state.cart);
  const [showAlert, setShowAlert] = useState(false);

  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  useEffect(() => {
    if (user && !user.tradingPlatformId) {
      setShowAlert(true);
    } else {
      setShowAlert(false);
    }
  }, [user]);

  return (
    <div className={headerClass}>
      <div className="container-lg wide-xl">
        <div className="nk-header-wrap">
          <div className="nk-header-brand">
            <Logo to={`${process.env.PUBLIC_URL}`} />
          </div>
          <div className={rtl ? "nk-header-tools-right" : "nk-header-tools"}>
            <ul className="nk-quick-nav">
              <li className="user-dropdown">
                <Link to="/cart" className={items?.length > 0 ? "cart-icon pulse-animation" : "cart-icon"}>
                  <Icon
                    name="cart"
                    className=""
                    style={{
                      fontSize: "1.3rem",
                    }}
                  />
                  {items?.length > 0 && (
                    <p
                      style={{
                        backgroundColor: "#a566f8",
                        width: "16px",
                        height: "16px",
                        aspectRatio: "1/1",
                        position: "absolute",
                        top: "-12px",
                        right: "-6px",
                        color: "#fff",
                        textAlign: "center",
                        borderRadius: "50%",
                        fontSize: "12px",
                      }}
                    >
                      {items?.length}
                    </p>
                  )}
                </Link>
              </li>
              <li className="user-dropdown">
                {user ? (
                  <User />
                ) : (
                  <Link to="/auth-login" className="btn btn-outline-primary btn-lg px-3 py-1">
                    Login
                  </Link>
                )}
              </li>
              <li className="d-lg-none">
                <Toggle icon="menu" className="toggle nk-quick-nav-icon me-n1" click={themeUpdate.sidebarVisibility} />
              </li>
            </ul>
          </div>
        </div>
        <div className="my-1">
          <Alert color="secondary" className="alert-dismissible" hidden={!showAlert}>
            <div className="d-flex align-items-center g-2 ">
              <span className="alert-text">
                Please set your TradingView ID in your profile settings to start trading.
              </span>
              <button className="btn-close" data-bs-dismiss="alert" onClick={() => setShowAlert(false)}></button>
            </div>
          </Alert>
        </div>
      </div>
    </div>
  );
};
export default Header;
