import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Badge, Card, Col, Modal, Row, Spinner } from "reactstrap";
import { formatDate, formatPrice } from "../../utils/Utils";
import { toast } from "react-toastify";
import { CancelSubscription } from "../../network/apis/subscriptions";
import { post } from "../../network/Config/apiService";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../redux/slices/CartSlice";

function SubscriptionCard({ item, refreshUI }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false);
  const [cancellingSubscription, setCancellingSubscription] = useState(false);

  const getSubscriptionEndData = (joinDate, planDuration = 1) => {
    const date = new Date(joinDate);
    const nextBillingDate = new Date(date.setMonth(date.getMonth() + planDuration));

    return nextBillingDate.toDateString();
  };

  const getTotalPaidAmount = () => {
    const currency = item?.planData?.currency;
    let totalAmount = 0;

    if (currency === "IRR") {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotTomansPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotTomansPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    } else {
      const totalAmountWithoutBot = Number(item?.planData?.withoutBotUsdPrice) * item?.planData?.duration;
      const totalAmountWithBot = Number(item?.planData?.withBotUsdPrice) * item?.planData?.duration;

      totalAmount = item?.planData?.isBotIncluded ? totalAmountWithBot : totalAmountWithoutBot;
    }

    const formattedAmount = formatPrice({
      currency: currency,
      amount: totalAmount,
    });

    return formattedAmount;
  };

  const [isUpgrading, setIsUpgrading] = useState(false);

  const upgradeSubscription = async () => {
    const oldPlanData = item?.planData;
    setIsUpgrading(true);

    const { isUpgradable, tomansPrice, usdPrice, planData } = await checkIfSubscriptionIsRenewableOrUpgradable();

    if (!isUpgradable) {
      setIsUpgrading(false);
      toast.error("You can't upgrade this plan. Please select another plan to continue.");
      return;
    } else {
      dispatch(
        addItemToCart({
          item: {
            priceInIRR: tomansPrice?.remainingAmount,
            priceInUSD: usdPrice?.remainingAmount,
            ...planData,
            planType: oldPlanData.planType,
            currency: oldPlanData.currency,
            duration: oldPlanData.duration,
            isBotIncluded: true,
            isUpgrade: true,
            subscriptionToUpgrade: item.subscriptionId,
          },
        })
      );
      navigate(`/cart`);
    }
  };

  const renewSubscription = async () => {
    const oldPlanData = item?.planData;
    setIsUpgrading(true);

    const { isRenewable, tomansPrice, usdPrice, planData } = await checkIfSubscriptionIsRenewableOrUpgradable();
    const isBotIncluded = oldPlanData?.isBotIncluded;

    if (isRenewable === false) {
      setIsUpgrading(false);
      toast.error("You can't renew this plan. Please select another plan to continue.");
      return;
    } else {
      dispatch(
        addItemToCart({
          item: {
            priceInIRR: isBotIncluded ? tomansPrice.totalAmountWithBot : tomansPrice.totalAmountWithoutBot,
            priceInUSD: isBotIncluded ? usdPrice.totalAmountWithBot : usdPrice.totalAmountWithoutBot,

            ...planData,
            planType: oldPlanData.planType,
            currency: oldPlanData.currency,
            isBotIncluded: oldPlanData.isBotIncluded,
            isRenew: true,
            subscriptionToRenew: item.subscriptionId,
          },
        })
      );
      navigate(`/cart`);
    }
  };

  const checkIfSubscriptionIsRenewableOrUpgradable = async () => {
    try {
      const res = await post(`/pricing/check-upgrade-renew`, {
        planId: item?.planData?.id,
        subscriptionId: item?.subscriptionId,
      });

      return res.data.data;
    } catch (error) {
      return { isRenewable: false, isUpgradable: false };
    }
  };

  const cancelSubscription = async () => {
    setCancellingSubscription(true);

    try {
      const res = await CancelSubscription(item?.subscriptionId);
      if (res.result === "success") {
        toast.success("Subscription canceled successfully");
        setSubscriptionCancelModal(false);
      } else {
        toast.error(res?.message ?? "Error canceling subscription");
      }
      setCancellingSubscription(false);
    } catch (error) {
      setCancellingSubscription(false);
      toast.error(error?.message ?? "Error canceling subscription");
    } finally {
      refreshUI();
    }
  };

  return (
    <>
      <Card className="card-bordered sp-plan">
        <Row className="no-gutters">
          <Col md="8">
            <div className="sp-plan-info card-inner">
              <Row className="gx-0 gy-3">
                <Col sm="12">
                  <div className="sp-plan-name">
                    <h6 className="title">
                      <Link to={`${process.env.PUBLIC_URL}/subscriptions`}>
                        {item?.planData?.title}{" "}
                        <Badge
                          color={
                            item.status?.toLowerCase() === "active"
                              ? "success"
                              : item.status?.toLowerCase() === "expired"
                              ? "warning"
                              : "danger"
                          }
                          className="rounded-pill"
                          style={{
                            textTransform: "capitalize",
                          }}
                        >
                          {item.status}
                        </Badge>
                      </Link>
                    </h6>
                    <p>
                      Subscription ID: <span className="text-base">{item?.subscriptionId}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="sp-plan-desc card-inner">
              <Row className="gx-1 gy-1">
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Started On</span> {formatDate(item?.date, "DD MMM YYYY")}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Expires On</span>{" "}
                    {getSubscriptionEndData(item?.date, item?.planData?.duration)}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Price</span>
                    {getTotalPaidAmount()}
                  </p>
                </Col>
                <Col col="6" lg="3">
                  <p>
                    <span className="text-soft">Duration </span>
                    {item?.planData?.duration} {item?.planData?.duration > 1 ? "Months" : "Month"}
                  </p>
                </Col>
                {item?.planData?.isBotOptionAvailable && (
                  <Col col="6" lg="3">
                    <p>
                      <span className="text-soft">Strategy Bot Access</span>{" "}
                      {item?.planData?.isBotIncluded ? "Yes" : "No"}
                    </p>
                  </Col>
                )}
              </Row>
            </div>
          </Col>
          <Col md="4">
            <div className="sp-plan-action card-inner">
              {item.status?.toLowerCase() === "active" &&
                item?.planData?.isBotOptionAvailable &&
                !item?.planData?.isBotIncluded && (
                  <>
                    <div className="sp-plan-btn">
                      <button
                        className="btn btn-primary"
                        onClick={() => {
                          upgradeSubscription();
                        }}
                        disabled={isUpgrading}
                      >
                        <span>
                          {isUpgrading ? (
                            <Spinner
                              size="sm"
                              color="light"
                              style={{
                                marginRight: "20px",
                                marginLeft: "20px",
                              }}
                            />
                          ) : (
                            "Upgrade Plan"
                          )}
                        </span>
                      </button>
                    </div>
                  </>
                )}
              {item.status?.toLowerCase() === "expired" && (
                <>
                  <div className="sp-plan-btn">
                    <button
                      className="btn btn-dim btn-white btn-outline-primary"
                      onClick={() => {
                        renewSubscription();
                      }}
                      disabled={isUpgrading}
                    >
                      {isUpgrading ? (
                        <Spinner
                          size="sm"
                          color="light"
                          style={{
                            marginRight: "20px",
                            marginLeft: "20px",
                          }}
                        />
                      ) : (
                        "Renew Plan"
                      )}
                    </button>
                  </div>
                  <div className="sp-plan-note text-md-center">
                    <p>You can renew the plan anytime.</p>
                  </div>
                </>
              )}
              {item.status?.toLowerCase() !== "expired" && (
                <div className="sp-plan-note text-md-center">
                  <p>
                    Next Billing on{" "}
                    <span>{item?.date && getSubscriptionEndData(item.date, item.planData.duration)}</span>
                  </p>
                </div>
              )}
              <button
                className="btn btn-link text-danger mt-1"
                onClick={() => {
                  setSubscriptionCancelModal(true);
                }}
              >
                Cancel Subscription
              </button>
            </div>
          </Col>
        </Row>
      </Card>

      <Modal isOpen={subscriptionCancelModal} size="md" toggle={() => setSubscriptionCancelModal(false)}>
        <button
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            setSubscriptionCancelModal(false);
          }}
        >
          <em className="icon ni ni-cross"></em>
        </button>
        <div className="modal-body modal-body-md">
          <h4 className="nk-modal-title title">Cancel Your Subscription</h4>
          <p>
            <strong>Are you sure you want to cancel your subscription?</strong>
          </p>
          <p>If you cancel, you'll lose your all access. But you can re-subscription your favourite plan any time.</p>
          <div className="form">
            <ul className="align-center flex-wrap g-3">
              <li>
                <button
                  className="btn btn-primary"
                  disabled={cancellingSubscription}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setCancellingSubscription(true);
                    cancelSubscription();
                  }}
                >
                  {cancellingSubscription ? <Spinner size="sm" color="light" /> : "Yes, Cancel Subscription"}
                </button>
              </li>
              <li>
                <button
                  className="btn btn-light"
                  onClick={(ev) => {
                    ev.preventDefault();
                    setSubscriptionCancelModal(false);
                  }}
                >
                  Never mind, don't cancel
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default SubscriptionCard;
