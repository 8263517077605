import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import AuthFooter from "./AuthFooter";
import {
  Block,
  BlockContent,
  BlockDes,
  BlockHead,
  BlockTitle,
  Button,
  Icon,
  PreviewCard,
} from "../../components/Component";
import { Link } from "react-router-dom";
import { Alert, Spinner } from "reactstrap";
import { ResetPassword } from "../../network/apis/auth";
import { toast } from "react-toastify";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");

  const onFormSubmit = async (ev) => {
    if (email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/)) {
      setLoading(true);
      try {
        // Call the ResetPassword function from the auth.js file in the firebase/functions folder
        const res = await ResetPassword(email);

        setLoading(false);
        if (res.result === "error") {
          setError(res.message);
        } else {
          // Redirect to the login page
          setEmail("");
          setError("");
          toast.success("Reset link sent to your email address");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.message ?? "Cannot send reset link");
      }
    } else {
      if (!email) {
        setError("Email is required");
        toast.error("Email is required");
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/)) {
        toast.error("Email is invalid");
        setError("Email is invalid");
      }
    }
  };

  useEffect(() => {
    if (errorVal) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [errorVal]);

  return (
    <>
      <Head title="Forgot-Password" />
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-4 text-center">
          <Link to={process.env.PUBLIC_URL + "/"} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <BlockTitle tag="h5">Reset password</BlockTitle>
              <BlockDes>
                <p>If you forgot your password, well, then we’ll email you instructions to reset your password.</p>
              </BlockDes>
            </BlockContent>
          </BlockHead>
          {errorVal && (
            <div className="mb-3">
              <Alert color="danger" className="alert-icon">
                <Icon name="alert-circle" /> {errorVal}
              </Alert>
            </div>
          )}
          <form>
            <div className="form-group">
              <div className="form-label-group">
                <label className="form-label" htmlFor="default-01">
                  Email
                </label>
              </div>
              <input
                type="email"
                className="form-control form-control-lg"
                id="default-01"
                placeholder="Enter your email address"
                value={email}
                onChange={(ev) => setEmail(ev.target.value)}
              />
            </div>
            <div className="form-group">
              <Button
                color="primary"
                size="lg"
                className="btn-block"
                onClick={(ev) => {
                  ev.preventDefault();
                  onFormSubmit(ev);
                }}
                disabled={loading}
              >
                {loading ? <Spinner size="sm" color="light" /> : "Send Reset Link"}
              </Button>
            </div>
          </form>
          <div className="form-note-s2 text-center pt-4">
            <Link to={`${process.env.PUBLIC_URL}/auth-login`}>
              <strong>Return to login</strong>
            </Link>
          </div>
        </PreviewCard>
      </Block>
      <AuthFooter />
    </>
  );
};
export default ForgotPassword;
