import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import Head from "../../layout/head/Head";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon } from "../../components/Component";
import { Link, useNavigate } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import AuthFooter from "./AuthFooter";
import { toast } from "react-toastify";
import { post } from "../../network/Config/apiService";

function VerifyEmail() {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resettingPassword, setResettingPassword] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [actionMode, setActionMode] = useState("resetPassword");
  const [actionCode, setActionCode] = useState("");

  const resetPassword = async () => {
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    } else if (!actionCode) {
      toast.error("Invalid action code");
      return;
    } else {
      try {
        setResettingPassword(true);
        const res = await post("/auth/reset-password", {
          token: actionCode,
          password: password,
        });

        if (res.data.result === "success") {
          toast.success(res.data.message ?? "Password reset successfully");
          navigate("/auth-login");
        } else {
          toast.error(res?.data?.message ?? "Cannot reset password");
        }
        setResettingPassword(false);
      } catch (error) {
        toast.error(error?.response?.data?.message ?? error.message ?? "Cannot reset password");
        setResettingPassword(false);
      }
    }
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      setActionMode("resetPassword");
      setActionCode(token);
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head title="Success" />
      <Block className="nk-block-middle nk-auth-body">
        <div className="brand-logo pb-5">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" />
          </Link>
        </div>
        <BlockHead>
          {actionMode === "resetPassword" && (
            <BlockContent>
              <BlockTitle tag="h4">Reset Password</BlockTitle>
              <BlockDes>
                <p>Enter your new password below to reset your password.</p>
              </BlockDes>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  resetPassword();
                }}
              >
                <div className="form-group">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="form-control-wrap d-flex align-items-center position-relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control form-control-lg"
                      id="password"
                      placeholder="Enter your new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn position-absolute end-0"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <Icon name={showPassword ? "eye-off" : "eye"} />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="confirmPassword">
                    Confirm Password
                  </label>
                  <div className="form-control-wrap d-flex align-items-center position-relative">
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      className="form-control form-control-lg"
                      id="confirmPassword"
                      placeholder="Confirm your new password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <button
                      type="button"
                      className="btn btn position-absolute end-0"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    >
                      <Icon name={showConfirmPassword ? "eye-off" : "eye"} />
                    </button>
                  </div>
                </div>
                <div className="form-group">
                  <Button type="submit" color="primary" size="lg" disabled={resettingPassword}>
                    {resettingPassword ? <Spinner size="sm" color="light" /> : "Reset Password"}
                  </Button>
                </div>
              </form>
            </BlockContent>
          )}
        </BlockHead>
      </Block>
      <AuthFooter />
    </>
  );
}

export default VerifyEmail;
