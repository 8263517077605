import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  items: [],
};

const CartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItemToCart: (state, action) => {
      // Check if item with id already exists in cart
      // If exists, replace the item with new item
      // If not, add the new item to cart

      const index = state.items.findIndex((item) => item.id === action.payload.item.id);
      if (index !== -1) {
        state.items[index] = action.payload.item;
      } else {
        state.items.push(action.payload.item);
      }
    },
    removeItemFromCart: (state, action) => {
      state.items = state.items.filter((item) => item.id !== action.payload.id);
    },
    clearCart: (state) => {
      state.items = [];
    },
    updateItem: (state, action) => {
      const index = state.items.findIndex((item) => item.id === action.payload.id);
      state.items[index] = action.payload.item;
    },
  },
});

export const { addItemToCart, clearCart, removeItemFromCart, updateItem } = CartSlice.actions;
export default CartSlice.reducer;
