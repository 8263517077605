import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { Button, RSelect, Block, Col, Icon, Row, UserAvatar } from "../../../components/Component";
import { Card, Spinner } from "reactstrap";
import DatePicker from "react-datepicker";
import { Modal, ModalBody } from "reactstrap";
import { bytesToMegaBytes, formatDate } from "../../../utils/Utils";
import { GetUserData, UpdateProfile, UpdateProfileImage, defaultProfile } from "../../../network/apis/profile";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../redux/slices/UserSlice";
import Dropzone from "react-dropzone";
import { iconsType } from "../../app/file-manager/components/Icons";
import { ResetPassword } from "../../../network/apis/auth";

const countryOptions = [
  { value: "Canada", label: "Canada" },
  { value: "USA", label: "USA" },
  { value: "India", label: "India" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "France", label: "France" },
  { value: "England", label: "England" },
];

const Profile = () => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [modalTab, setModalTab] = useState("1");
  const [userInfo, setUserInfo] = useState(defaultProfile);
  const [formData, setFormData] = useState(defaultProfile);
  const [modal, setModal] = useState(false);
  const [updatingProfile, setUpdatingProfile] = useState(false);
  const [fetchingProfile, setFetchingProfile] = useState(false);
  const [profileImage, setProfileImage] = useState(null);
  const [isUpdatingProfileImage, setIsUpdatingProfileImage] = useState(false);

  const [isSendingResetPasswordEmail, setIsSendingResetPasswordEmail] = useState(false);

  const onInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const submitForm = async () => {
    let submitData = {
      ...formData,
    };

    const { name, address, address2, country, displayName, dob, phone, state, tradingPlatformId } = submitData;

    if (name?.trim() && displayName?.trim() && phone?.trim() && tradingPlatformId?.trim()) {
      setUpdatingProfile(true);
      try {
        const res = await UpdateProfile(user?.uid, {
          name: name,
          displayName: displayName,
          dob: dob ?? "",
          email: userInfo.email,
          phone: phone,
          address: address ?? "",
          state: state ?? "",
          country: country ?? "",
          address2: address2 ?? "",
          tradingPlatformId: tradingPlatformId,
        });

        if (res.result === "success") {
          setUserInfo(res.data);
          const userData = {
            ...user,
            ...res.data,
          };
          dispatch(
            setUserData({
              user: userData,
            })
          );

          // Set Cookie
          document.cookie = `user=${JSON.stringify({
            ...userData,
            photoURL: encodeURIComponent(userData.photoURL),
          })}; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/; max-age=2592000;`;

          setModal(false);
          toast.success("Profile updated successfully");
        } else {
          toast.error(res?.message ?? "Something went wrong. Try Again!");
        }

        setUpdatingProfile(false);
      } catch (error) {
        toast.error(error?.message ?? "Something went wrong. Try Again!");
        setUpdatingProfile(false);
      }
    } else {
      toast.error("Please fill all fields");
      if (!name?.trim()) {
        toast.warn("Full Name is missing!");
      }
      if (!displayName?.trim()) {
        toast.warn("Display Name is missing!");
      }
      if (!phone) {
        toast.warn("Phone no is missing!");
      }
      if (!tradingPlatformId?.trim()) {
        toast.warn("TradingView ID is missing!");
      }
    }
  };

  const getUserProfile = async (userId) => {
    setFetchingProfile(true);
    try {
      const res = await GetUserData(userId);
      if (res.result === "success") {
        setUserInfo(res.userData);
        setFormData(res.userData);
      } else {
        toast.error(res?.message ?? "Error fetching profile data");
      }
      setFetchingProfile(false);
    } catch (error) {
      setFetchingProfile(false);
      toast.error(error?.message ?? "Error fetching profile data");
    }
  };

  const updateUserProfileImage = async () => {
    if (!profileImage) {
      toast.error("Please select a file to upload");
    } else {
      try {
        setIsUpdatingProfileImage(true);
        const res = await UpdateProfileImage(user?.uid, profileImage);

        if (res.result === "success") {
          toast.success("Profile image updated successfully");
          getUserProfile(user?.uid);
          // Set Cookie
          document.cookie = `user=${JSON.stringify({
            ...user,
            photoURL: encodeURIComponent(res.data),
          })}; domain=${process.env.REACT_APP_MAIN_WEBSITE_DOMAIN}; path=/; max-age=2592000;`;

          dispatch(
            setUserData({
              user: {
                ...user,
                photoURL: res.data,
              },
            })
          );

          setModal(false);
        } else {
          toast.error(res?.message ?? "Error updating profile image");
        }

        setIsUpdatingProfileImage(false);
      } catch (error) {
        setIsUpdatingProfileImage(false);
        toast.error(error?.message ?? "Error updating profile image");
      }
    }
  };

  const handleDropChange = (acceptedFiles) => {
    // Check if file is not empty
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      setProfileImage(file);
    } else {
      setFormData({ ...formData, photoURL: "" });
    }
  };

  const resetPassword = async (email) => {
    try {
      setIsSendingResetPasswordEmail(true);
      // Call the ResetPassword function from the auth.js file in the firebase/functions folder
      const res = await ResetPassword(email);

      setIsSendingResetPasswordEmail(false);
      if (res.result === "error") {
        toast.error(res.message ?? "Cannot send reset link");
      } else {
        toast.success("Reset link sent to your email address");
      }
    } catch (error) {
      setIsSendingResetPasswordEmail(false);
      toast.error(error?.message ?? "Cannot send reset link");
    }
  };

  useEffect(() => {
    if (user?.uid) {
      getUserProfile(user.uid);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Head title="Profile Info"></Head>

      <Block>
        <Card className="card-bordered">
          {fetchingProfile ? (
            <section className="d-flex flex-row justify-content-center align-items-center my-5 py-5 ">
              <Spinner size="lg" color="primary" />
            </section>
          ) : (
            <div className="nk-data data-list">
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Profile Image</span>
                  <UserAvatar
                    image={userInfo?.photoURL ?? ""}
                    text={
                      userInfo?.name
                        ? userInfo.name.slice(0, 1).toUpperCase() + userInfo.name.slice(1, 2).toUpperCase()
                        : ""
                    }
                    imageAlt={userInfo?.fullName ?? ""}
                  />
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Full Name</span>
                  <span className="data-value">{userInfo.fullName}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more">
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Display Name</span>
                  <span className="data-value">{userInfo.displayName}</span>
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Email</span>
                  <span className="data-value">{userInfo.email}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more disable">
                    <Icon name="lock-alt"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Navixa Id</span>
                  <span className="data-value">{userInfo.uid}</span>
                </div>
                <div className="data-col data-col-end">
                  <span className="data-more disable">
                    <Icon name="lock-alt"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">TradingView ID</span>
                  <span className="data-value text-soft">{userInfo.tradingPlatformId}</span>
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Phone Number</span>
                  <span className="data-value text-soft">{userInfo.phone}</span>
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Date of Birth</span>
                  <span className="data-value">{formatDate(userInfo?.dob ?? "", "DD MMM YYYY")}</span>
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>
              <div className="data-item" onClick={() => setModal(true)}>
                <div className="data-col">
                  <span className="data-label">Address</span>
                  <span className="data-value">
                    {userInfo.address ? `${userInfo.address}, ` : ""}{" "}
                    {userInfo.address2 ? `${userInfo.address2}, ` : ""}
                    {userInfo.address || userInfo.address2 ? <br /> : ""}
                    {userInfo.state ? `${userInfo.state}, ` : ""} {userInfo.country ? `${userInfo.country}` : ""}
                  </span>
                </div>
                <div className="data-col data-col-end">
                  <span
                    className="data-more"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <Icon name="forward-ios"></Icon>
                  </span>
                </div>
              </div>

              <div className="data-item">
                <div className="data-col">
                  <span className="data-label">Reset Password</span>
                </div>
                <div className="data-col data-col-end">
                  <Button
                    color="primary"
                    size="sm"
                    disabled={isSendingResetPasswordEmail}
                    onClick={() => {
                      resetPassword(userInfo.email);
                    }}
                  >
                    {isSendingResetPasswordEmail ? <Spinner size="sm" color="light" /> : "Reset Password"}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </Card>
      </Block>
      <Modal isOpen={modal} className="modal-dialog-centered" size="lg" toggle={() => setModal(false)}>
        <ModalBody>
          <a
            href="#dropdownitem"
            onClick={(ev) => {
              ev.preventDefault();
              setModal(false);
            }}
            className="close"
          >
            <Icon name="cross-sm"></Icon>
          </a>
          <div className="p-2">
            <h5 className="title">Update Profile</h5>
            <ul className="nk-nav nav nav-tabs">
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "1" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("1");
                  }}
                  href="#personal"
                >
                  Personal
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "2" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("2");
                  }}
                  href="#address"
                >
                  Address
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${modalTab === "3" && "active"}`}
                  onClick={(ev) => {
                    ev.preventDefault();
                    setModalTab("3");
                  }}
                  href="#address"
                >
                  Profile Image
                </a>
              </li>
            </ul>
            <div className="tab-content">
              <div className={`tab-pane ${modalTab === "1" ? "active" : ""}`} id="personal">
                <Row className="gy-4">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="full-name">
                        Full Name
                      </label>
                      <input
                        type="text"
                        id="full-name"
                        className="form-control"
                        name="name"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.name}
                        placeholder="Enter Full name"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="display-name">
                        Display Name
                      </label>
                      <input
                        type="text"
                        id="display-name"
                        className="form-control"
                        name="displayName"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.displayName}
                        placeholder="Enter display name"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="phone-no">
                        Phone Number
                      </label>
                      <input
                        type="number"
                        id="phone-no"
                        className="form-control"
                        name="phone"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.phone}
                        placeholder="Phone Number"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="tradingPlatformId">
                        TradingView ID
                      </label>
                      <input
                        type="text"
                        id="tradingPlatformId"
                        className="form-control"
                        name="tradingPlatformId"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.tradingPlatformId ?? ""}
                        placeholder="TradingView Id"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="birth-day">
                        Date of Birth
                      </label>
                      <DatePicker
                        selected={new Date(formData?.dob ?? "")}
                        className="form-control"
                        onChange={(date) => {
                          if (date) {
                            const jsDate = new Date(date);
                            setFormData({ ...formData, dob: jsDate ? jsDate.toISOString() : new Date() });
                          }
                        }}
                        maxDate={new Date()}
                      />
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }}
                          disabled={updatingProfile || fetchingProfile}
                        >
                          {updatingProfile ? <Spinner size="sm" color="light" /> : "Update Profile"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                            setFormData(userInfo);
                          }}
                          className="link link-light"
                          disabled={updatingProfile}
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "2" ? "active" : ""}`} id="address">
                <Row className="gy-4">
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l1">
                        Address Line 1
                      </label>
                      <input
                        type="text"
                        id="address-l1"
                        name="address"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.address}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-l2">
                        Address Line 2
                      </label>
                      <input
                        type="text"
                        id="address-l2"
                        name="address2"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.address2}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-st">
                        State
                      </label>
                      <input
                        type="text"
                        id="address-st"
                        name="state"
                        onChange={(e) => onInputChange(e)}
                        defaultValue={formData.state}
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="form-group">
                      <label className="form-label" htmlFor="address-county">
                        Country
                      </label>
                      <RSelect
                        options={countryOptions}
                        placeholder="Select a country"
                        defaultValue={[
                          {
                            value: formData.country,
                            label: formData.country,
                          },
                        ]}
                        onChange={(e) => setFormData({ ...formData, country: e.value })}
                      />
                    </div>
                  </Col>
                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            submitForm();
                          }}
                          disabled={updatingProfile || fetchingProfile}
                        >
                          {updatingProfile ? <Spinner size="sm" color="light" /> : "Update Address"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                            setFormData(userInfo);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
              <div className={`tab-pane ${modalTab === "3" ? "active" : ""}`} id="profile-image">
                <Row className="gy-4">
                  <div className="form-group">
                    <div className="nk-upload-form w-full">
                      <h5 className="title mb-3">Upload Profile Image</h5>
                      <Dropzone
                        onDrop={(acceptedFiles) => handleDropChange(acceptedFiles)}
                        accept={"image/*"}
                        maxSize={1048576}
                        maxFiles={1}
                        onDropRejected={(fileRejections) => {
                          if (fileRejections.length > 0) {
                            // toast.error("File size is too large. Max file size is 1MB");
                            toast.error(
                              fileRejections[0]?.errors[0]?.message ?? "File size is too large. Max file size is 1MB"
                            );
                          }
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <section>
                            <div
                              {...getRootProps()}
                              className="dropzone upload-zone small bg-lighter my-2 dz-clickable"
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message">
                                <span className="dz-message-text">
                                  <span>Drag and drop</span> file here or <span>browse</span>{" "}
                                  <span className="text-soft"> (Max 1MB)</span>
                                </span>
                              </div>
                            </div>
                          </section>
                        )}
                      </Dropzone>

                      {profileImage && (
                        <div className="nk-upload-item">
                          <div className="nk-upload-icon">
                            {iconsType[profileImage.type] ? iconsType[profileImage.type] : iconsType["others"]}
                          </div>
                          <div className="nk-upload-info">
                            <div className="nk-upload-title">
                              <span className="title">{profileImage.name}</span>
                            </div>
                            <div className="nk-upload-size">{bytesToMegaBytes(profileImage.size)} MB</div>
                          </div>
                          <div className="nk-upload-action">
                            <a
                              href="#delete"
                              onClick={(ev) => {
                                ev.preventDefault();
                                setProfileImage(null);
                              }}
                              className="btn btn-icon btn-trigger"
                            >
                              <Icon name="trash"></Icon>
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <Col size="12">
                    <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                      <li>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={(ev) => {
                            ev.preventDefault();
                            updateUserProfileImage();
                          }}
                          disabled={updatingProfile || fetchingProfile}
                        >
                          {isUpdatingProfileImage ? <Spinner size="sm" color="light" /> : "Update Profile Image"}
                        </Button>
                      </li>
                      <li>
                        <a
                          href="#dropdownitem"
                          onClick={(ev) => {
                            ev.preventDefault();
                            setModal(false);
                            setFormData(userInfo);
                          }}
                          className="link link-light"
                        >
                          Cancel
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default Profile;
