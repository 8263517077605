import React, { useEffect, useState } from "react";
import { BlockContent, BlockDes, BlockHead, BlockTitle, Button, Icon } from "../../../components/Component";
import { Alert, Spinner } from "reactstrap";
import { ResetPassword } from "../../../network/apis/auth";
import { toast } from "react-toastify";

function PasswordResetForm({ showSignIn = () => {} }) {
  const [loading, setLoading] = useState(false);
  const [errorVal, setError] = useState("");
  const [email, setEmail] = useState("");

  const onFormSubmit = async () => {
    if (email && email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/)) {
      setLoading(true);
      try {
        // Call the ResetPassword function from the auth.js file in the firebase/functions folder
        const res = await ResetPassword(email);

        setLoading(false);
        if (res.result === "error") {
          setError(res.message);
        } else {
          // Redirect to the login page
          setEmail("");
          setError("");
          toast.success("Reset link sent to your email address");
        }
      } catch (error) {
        setLoading(false);
        toast.error(error?.message ?? "Cannot send reset link");
      }
    } else {
      if (!email) {
        setError("Email is required");
        toast.error("Email is required");
      } else if (!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/)) {
        toast.error("Email is invalid");
        setError("Email is invalid");
      }
    }
  };

  useEffect(() => {
    if (errorVal) {
      setTimeout(() => {
        setError("");
      }, 3000);
    }
  }, [errorVal]);

  return (
    <section>
      <BlockHead>
        <BlockContent>
          <BlockTitle tag="h4">Password Reset</BlockTitle>
          <BlockDes>
            <p>Enter your email address to reset your password.</p>
          </BlockDes>
        </BlockContent>
      </BlockHead>
      {errorVal && (
        <div className="mb-3">
          <Alert color="danger" className="alert-icon">
            <Icon name="alert-circle" /> {errorVal}
          </Alert>
        </div>
      )}
      <form
        className="is-alter"
        onSubmit={(e) => {
          e.preventDefault();
          onFormSubmit();
        }}
      >
        <div className="form-group">
          <div className="form-label-group">
            <label className="form-label" htmlFor="default-01">
              Email
            </label>
          </div>
          <div className="form-control-wrap">
            <input
              type="email"
              id="default-01"
              defaultValue=""
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email address"
              className="form-control-lg form-control"
              required
            />
          </div>
        </div>

        <div className="form-group">
          <Button size="lg" className="btn-block" type="submit" color="primary" disabled={loading}>
            {loading ? <Spinner size="sm" color="light" /> : "Reset Password"}
          </Button>
        </div>
      </form>

      <div className="form-note-s2 text-center pt-4">
        Know your passcode?{" "}
        <button
          type="button"
          className="link link-primary link-sm"
          onClick={() => {
            showSignIn();
          }}
        >
          Sign-In Here
        </button>
      </div>
    </section>
  );
}

export default PasswordResetForm;
