import React from "react";
import { Card } from "reactstrap";
import { formatDate } from "../../utils/Utils";

function SubscriptionDetailsCard({ currentPlanDetails }) {
  const getSubscriptionEndData = (joinDate, planType) => {
    const date = new Date(joinDate);

    if (planType === "yearly") {
      const nextBillingDate = new Date(date.setFullYear(date.getFullYear() + 1));
      return nextBillingDate.toDateString();
    } else {
      const nextBillingDate = new Date(date.setMonth(date.getMonth() + 1));
      return nextBillingDate.toDateString();
    }
  };

  return (
    <Card className="card-bordered">
      <div className="card-inner-group">
        <div className="card-inner">
          <div className="sp-plan-head">
            <h6 className="title">Plan Details</h6>
          </div>

          <div className="sp-plan-desc sp-plan-desc-mb">
            <ul className="row gx-1">
              <li className="col-sm-4">
                <p>
                  <span className="text-soft">Plan Name</span> {currentPlanDetails?.planData?.title ?? ""} Plan
                </p>
              </li>
              <li className="col-sm-4">
                <p>
                  <span className="text-soft">Started On</span>{" "}
                  {formatDate(currentPlanDetails?.date ?? "", "DD MMM YYYY")}
                </p>
              </li>
              <li className="col-sm-4">
                <p>
                  <span className="text-soft">Price</span>
                  {currentPlanDetails?.planData?.currency === "IRR"
                    ? currentPlanDetails?.planData?.planType === "yearly"
                      ? "IRR " + currentPlanDetails?.planData?.yearlyUSD + " / Year"
                      : "IRR " + currentPlanDetails?.planData?.monthlyUSD + " / Month"
                    : currentPlanDetails?.planData?.planType === "yearly"
                    ? "USD " + currentPlanDetails?.planData?.yearlyUSD + " / Year"
                    : "USD " + currentPlanDetails?.planData?.monthlyUSD + " / Month"}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div className="card-inner">
          <div className="sp-plan-head-group">
            <div className="sp-plan-head">
              <h6 className="title">Next Payment</h6>
              <span className="ff-italic text-soft">
                Your next payment will be on{" "}
                {getSubscriptionEndData(currentPlanDetails.date, currentPlanDetails.planData.planType)}
              </span>
            </div>
            <div className="sp-plan-amount">
              <span
                className="sp-plan-status text-warning"
                style={{
                  minWidth: "max-content",
                }}
              >
                Upcoming
              </span>{" "}
              <span className="amount">
                {currentPlanDetails?.planData?.currency === "IRR"
                  ? currentPlanDetails?.planData?.planType === "yearly"
                    ? "IRR " + currentPlanDetails?.planData?.yearlyUSD
                    : "IRR " + currentPlanDetails?.planData?.monthlyUSD
                  : currentPlanDetails?.planData?.planType === "yearly"
                  ? "USD " + currentPlanDetails?.planData?.yearlyUSD
                  : "USD " + currentPlanDetails?.planData?.monthlyUSD}
              </span>
            </div>
          </div>
        </div>
        <div className="card-inner">
          <div className="sp-plan-head-group">
            <div className="sp-plan-head">
              <h6 className="title">Access</h6>

              <ul className="list list-unstyled list-featured">
                {currentPlanDetails?.planData?.features.map((feature, index) => (
                  <li key={index} className="text-soft">
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default SubscriptionDetailsCard;
